export const kadunaCommunity = [
  {lga: 'Igabi', Community: 'Rigasa/Hayin Nariya'},
  {lga: 'Igabi', Community: 'Mando'},
  {lga: 'Igabi', Community: 'Barakallahu'},
  {lga: 'Kaduna-South', Community: 'Kabala West/Tudun Wada'},
  {lga: 'Kaduna-South', Community: 'Kakuri'},
  {lga: 'Kaduna-South', Community: 'Kurmin Mashi'},
  {lga: 'Kauru', Community: 'Badurum/Bakin Kogi'},
  {lga: 'Kauru', Community: 'Damakasuwa/Fadan Chawe'},
  {lga: 'Kauru', Community: 'Kikuba'},
  {lga: 'Sabon-Gari', Community: 'Samaru'},
  {lga: 'Sabon-Gari', Community: 'Angwan Jabba'},
  {lga: 'Sabon-Gari', Community: 'Dogon Bauchi'},
  {lga: 'Sabon-Gari', Community: 'Angwan Mai Gwado'},
  {lga: 'Sabon-Gari', Community: 'Park Road'},
  {lga: 'Zango-Kataf', Community: 'Angwan Gaya/Tanjei'},
  {lga: 'Zango-Kataf', Community: 'Zango Urban'},
  {lga: 'Zango-Kataf', Community: 'Kurmin Masara'},
]

export const PlateauCommunities = [
  {lga: 'Jos-North', Community: 'Bible Faith/Rafin Pa'},
  {lga: 'Jos-North', Community: 'Fillin Shaidan/Ungwan Rukuba'},
  {lga: 'Jos-North', Community: 'Naraguta/Kunga '},
  {lga: 'Jos-North', Community: 'Ubyel/Febuna '},
  {lga: 'Jos-North', Community: 'University of Jos/ Angwan Rogo  '},
  {lga: 'Jos-North', Community: 'Abba Na Shehu'},
  {lga: 'Jos-North', Community: 'Ali Kazaure '},
  {lga: 'Jos-North', Community: 'Angwan Jarawa '},
  {lga: 'Jos-North', Community: 'Kongo Bulbula'},
  {lga: 'Jos-North', Community: 'St Micheal/Yanshanu/Fillin Ball'},
  {lga: 'Jos-North', Community: 'Yan Trailer/Tina Juction/Dutse Uku'},
  {lga: 'Mangu', Community: 'Panyam'},
  {lga: 'Mangu', Community: 'Kwahas Lalek'},
  {lga: 'Mangu', Community: 'Kadunu'},
  {lga: 'Mangu', Community: 'Kulben'},
  {lga: 'Mangu', Community: 'Mangu'},
  {lga: 'Jos-South', Community: 'Bukuru'},
  {lga: 'Jos-South', Community: 'Gyel'},
  {lga: 'Jos-South', Community: 'Kwata Zawan'},
  {lga: 'Jos-South', Community: 'Gyero'},
  {lga: 'Jos-South', Community: 'Anguldi'},
  {lga: 'Jos-South', Community: 'Dadin Kowa'},
  {lga: 'Jos-South', Community: 'Dogo Na Hawa'},
  {lga: 'Jos-South', Community: 'Rantya'},
]